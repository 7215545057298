<template>
  <div style="max-width: 460px" class="hovering__pad m-auto py-4 p-3 px-xsm-4 text-center mb-5">
    <div class="px-3">
      <img class="m-auto mt-3" src="@main/assets/img/svg/done_icon.svg" alt="" />
      <h1 class="mt-4">
        Подписка оформлена {{ $route.query.once ? '' : '- автопродление включено' }}
      </h1>
      <p v-if="!$route.query.email" class="mt-3">
        Вы можете пользоваться всеми сервисами Easyprbot. Управлять подпиской
        {{ $route.query.once ? '' : 'или отключить автопродление' }}
        можно на странице
        <b-button variant="link" :to="{ name: 'payments-settings' }">Управление подпиской</b-button
        >.
      </p>
      <p v-else>
        <br />
        Данные для входа в скором времени придут на ваш e-mail адрес -
        {{ $route.query.email }}<br /><br />
        Обычно это длится не более 10 минут, если письмо не приходит - обратитесь на
        <a href="mailto:help@easyprbot.com">help@easyprbot.com</a> и мы отошлем вам письмо с
        доступами.
      </p>
    </div>
    <div class="d-flex justify-content-between flex-wrap px-3">
      <div class="mt-4" v-for="btn in activeServices" :key="btn.id">
        <b-button
          class="my-1"
          :to="!btn.link && { name: btn.to, query: btn.query }"
          :href="btn.link"
          variant="link"
          style="
            border-radius: 25px;
            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), inset 0px 0px 4px #f2f2f2;
            border: 1px solid #d5dfe4;
            border-radius: 25.5px;
          "
        >
          <img
            style="width: 95px; height: 95px"
            :src="btn.icon_desktop"
            :alt="btn.text"
            :disabled="btn.disabled"
            :title="btn.text"
          />
        </b-button>
        <p
          class="mt-2"
          style="
            width: 115px;
            font-family: Rubik;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #192229;
          "
        >
          {{ btn.text }}
        </p>
      </div>
    </div>
    <!--div style="background-color: #f8f8f8; border-radius: 10px" class="p-3 mt-4">
      <p class="text-left ml-2 mt-2 mt-sxm-0">Скоро в подписке</p>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="mt-4" v-for="btn in incomingServices" :key="btn.id">
          <b-button
            class="my-1"
            :to="!btn.link && { name: btn.to, query: btn.query }"
            :href="btn.link"
            variant="link"
            style="
              border-radius: 25px;
              box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), inset 0px 0px 4px #f2f2f2;
              border: 1px solid #d5dfe4;
              border-radius: 25.5px;
            "
          >
            <img
              style="width: 95px; height: 95px"
              :src="btn.icon_desktop"
              :alt="btn.text"
              :disabled="btn.disabled"
              :title="btn.text"
            />
          </b-button>
          <p
            class="mt-2"
            style="
              width: 115px;
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              color: #192229;
            "
          >
            {{ btn.text }}
          </p>
        </div>
      </div>
    </div-->
  </div>
</template>

<script>
import ui from '@main/api/ui';

export default {
  data: () => ({
    navButtons: [],
  }),
  computed: {
    incomingServices() {
      return this.navButtons.filter((val) => val.incoming === true);
    },
    activeServices() {
      return this.navButtons.filter((val) => val.incoming === false);
    },
  },
  async mounted() {
    const buttons = await ui.buttons.activeServices.get();
    this.navButtons = buttons.results;
  },
};
</script>
